<template>
  <div>
    <el-form v-model="queryParams" :inline="true" label-position="right" label-width="110px" size="small">
      <el-form-item label="手机号" prop="telephone" size="mini">
        <el-input v-model="queryParams.telephone" class="condition-input" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
        <el-button type="danger" icon="el-icon-search" @click="open=true">直接呼叫</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" style="width: 100%" stripe fit max-height="640">
      <el-table-column align="center" label="机会ID" prop="id"/>
      <el-table-column align="center" label="手机号" prop="telephone"/>
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="callDoubleing($event,scope.row.id)">双呼</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total">
    </el-pagination>

    <el-dialog title="呼叫盘" :visible.sync="open" :close-on-click-modal="false" append-to-body>
      <el-form :model="pwform" label-width="100px" :rules="pwrules" ref="pwform">
        <el-form-item label="拨打号码：" prop="phone" required>
          <el-input v-model="pwform.phone" autocomplete="off" style="width: 70%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">取 消</el-button>
        <el-button type="primary" @click="cellPhone">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from "@/utils/api";
import {cellPhone} from "@/utils/api";

export default {
  name: "Teacher",
  data() {
    return {
      open: false,
      queryParams: {},
      page: {current: 1, size: 10, total: 0},
      tableData: [],
      loading: false,
      pwform: {
        phone: '',
      },
      pwrules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    //呼叫手机号
    cellPhone() {
      this.$refs['pwform'].validate((valid) => {
        if (valid) {
          api.cellPhone(this.pwform.phone).then(res => {
            console.log(res)
            if (res.success) {
              this.$message.success("拨打成功,正在呼叫中!")
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error("手机号信息错误")
        }
      })
    },
    adviserWechat(id) {
      api.findAdviserWechat(id).then(res => {
        if (res.data) {
          let names = ''
          for (let k of res.data) {
            names += k + '<br/>'
          }
          this.$alert(names, '当前加过微信咨询顾问:', {
            confirmButtonText: '确定',
            dangerouslyUseHTMLString: true
          });
        }
      })
    },
    dueConditionTime: function () {
      if (this.queryParams.uploadTime) {
        let uploadTime = this.queryParams.uploadTime
        let createTimeStart = uploadTime[0].format("yyyy-MM-dd HH:mm:ss")
        let createTimeEnd = uploadTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.queryParams.createTimeStart = createTimeStart
        this.queryParams.createTimeEnd = createTimeEnd
      } else {
        this.queryParams.createTimeStart = ""
        this.queryParams.createTimeEnd = ""
      }
      if (this.queryParams.allotTime) {
        let allotTime = this.queryParams.allotTime
        let vallotTimeStart = allotTime[0].format("yyyy-MM-dd HH:mm:ss")
        let vallotTimeEnd = allotTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.queryParams.vallotTimeStart = vallotTimeStart
        this.queryParams.vallotTimeEnd = vallotTimeEnd
      } else {
        this.queryParams.vallotTimeStart = ""
        this.queryParams.vallotTimeEnd = ""
      }
      if (this.queryParams.collectionTimeRange) {
        let collectionTime = this.queryParams.collectionTimeRange
        let collectionTimeStart = collectionTime[0].format("yyyy-MM-dd HH:mm:ss")
        let collectionTimeEnd = collectionTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.queryParams.collectionTimeStart = collectionTimeStart
        this.queryParams.collectionTimeEnd = collectionTimeEnd
      } else {
        this.queryParams.collectionTimeStart = ""
        this.queryParams.collectionTimeEnd = ""
      }

      if (this.queryParams.benginFrozenTimeRange) {
        let beginFrozenTime = this.queryParams.benginFrozenTimeRange
        let beginFreezeTimeStart = beginFrozenTime[0].format("yyyy-MM-dd HH:mm:ss")
        let beginFreezeTimeEnd = beginFrozenTime[1].format("yyyy-MM-dd HH:mm:ss")
        this.queryParams.beginFreezeTimeStart = beginFreezeTimeStart
        this.queryParams.beginFreezeTimeEnd = beginFreezeTimeEnd
      } else {
        this.queryParams.beginFreezeTimeStart = ""
        this.queryParams.beginFreezeTimeEnd = ""
      }
    },
    /**
     * 搜索
     */
    doSearch() {
      if (!this.queryParams.telephone) {
        this.$message.warning("请输入手机号")
        return;
      }
      this.page.current = 1
      this.dueConditionTime()

      for (let item in this.extra) {
        if (this.extra[item]) {
          this.e[item] = this.extra[item][0] + ',' + this.extra[item][1]
        } else {
          delete this.e[item]
        }
      }
      this.queryParams['e'] = this.e
      this.getOpportunityList(this.queryParams)
    },
    /**
     * 获取普通机会列表
     * @param params
     */
    getOpportunityList: function (params) {
      this.loading = true
      this.bankOpporColumns = this.opporTableColumns
      this.basicOpportunity(params)
    },
    basicOpportunity: function (params) {
      if (!this.queryParams.telephone) {
        this.$message.warning("请输入手机号")
        return;
      }
      if (params.telephone) {
        let telephone = params.telephone.trim()
        telephone = telephone.replace("​", "")
        if (telephone.length > 11) {
          this.$message.warning("手机号长度错误!");
          this.loading = false
          return
        }
        params.telephone = telephone
      }
      params['isFreeze'] = 9
      api.opporListJson(this.page.current, this.page.size, params).then(res => {
        if (res.success) {
          let resEntity = res.data
          this.page.current = resEntity.current
          this.page.size = resEntity.size
          this.page.total = resEntity.total
          let e = resEntity.records
          for (let i in e) {
            let ent = e[i]
            if (ent['extra']) {
              let tson = JSON.parse(ent['extra'])
              for (let j in tson) {
                ent[j] = tson[j]
              }
            }
          }
          this.tableData = e
        } else {
          this.$message.warning(res.msg);
        }
        this.loading = false
      }).catch(error => {
        this.$message.error("获取机会列表失败");
        this.loading = false
      })
    },
    /**
     * 双呼拨打
     */
    callDoubleing(e, id) {
      api.doubleCall(id).then(res => {
        if (res.success) {
          //隐藏当前点击的拨打按钮，显示挂断按钮
          e.target.innerText = '已呼叫'
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        this.$message.warning("拨打失败");
      })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.loading = true
      this.getOpportunityList(this.queryParams)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.loading = true
      this.getOpportunityList(this.queryParams)
    },
  }
}
</script>

<style scoped>

</style>